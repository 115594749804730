import './App.css';
import Card from './components/Card';
import Text from './components/Text';
import ImageText from './components/ImageText';
import first from './1.png';
import second from './2.png';
import third from './3.png';
import fourth from './4.png';
import Footer from './components/Footer';
import CardTwo from './components/CardTwo';

function App() {
  const textItem1 = "Make your digital transformation journey super simple, safe, and aligned with your specific business needs. We're good at strategy planning for both enterprises and SMBs, IT audit, business process analysis, and more.";
  const textItem2 = "Achieve lower costs, utmost scalability and maximum velocity with our legacy software modernization, cloud, and Devops services. Our enterprise software development company is here to help you remove the roadblocks to innovation.";
  const textItem3 = "Build enterprise software solutions that perform. We can cover you along the whole way or fill in specific skill gaps in business analysis, UX/UI, testing, development, or support across web, mobile, desktop, cloud, and embedded platforms.";
  const textItems = ['DISPLAY YOUR TOP PRODUCT ON YOUR HOMEPAGE',
                     'SELL YOURSELF CLEARLY ON YOUR HOMEPAGE',
                     'USE TESTIMONIALS, ASSOCIATIONS, AND CASE STUDIES',
                     'CREATE CONTENT THAT TARGETS SPECIFIC MARKET SEGMENTS',
                     'INCORPORATE VIDEOS INTO YOUR KEY CONVERSION PAGES',
                     'FIND WAYS TO CAPTURE EMAIL ADDRESSES',
                     'INCORPORATE UPSELLING ON YOUR WEBSITE',
                     'CONSIDER SETTING UP CONVERSION TRACKING',
                     'INSTALL LIVE CHAT',
                     'CONSIDER GUEST POSTING'];

  const text1 = 'We create amazing designs';
  const text2 = 'WordPress experts';
  return (
    <div className="App">
      <Card text1={text1} text2={text2}/><br /> <br />
      <Text />
      <ImageText
        image={first}
        header="Innovation Consulting"
        content={textItem1}
        imageOnRight={false} // Change to false for image on the left
      />
      <ImageText
        image={second}
        header="Digital Transformation"
        content={textItem2}
        imageOnRight={true} // Change to false for image on the left
      />
      <ImageText
        image={third}
        header="Software Engineering"
        content={textItem3}
        imageOnRight={false} // Change to false for image on the left
      />
      <ImageText
        image={fourth}
        header="How your website can help increase your leads and sales?"
        content={textItems}
        imageOnRight={true} // Change to false for image on the left
      />

      <div className="cardTwoCenter">
        <CardTwo />
      </div>
      <Footer />
    </div>
  );
}

export default App;
