import React from 'react';
import './ImageText.css'; // Import the CSS file for styling

const ImageText = ({ image, header, content, imageOnRight = true }) => {
  let contentToRender;

  if (Array.isArray(content)) {
    contentToRender = (
      <ul>
        {content.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else {
    contentToRender = <p>{content}</p>;
  }
  return (
    <div className={`image-text-container ${imageOnRight ? 'image-on-right' : 'image-on-left'}`}>
      <div className="text-section">
        <h1>{header}</h1>
        {contentToRender}
      </div>
      <div className="image-section">
        <img className="image-text" src={image} alt="Image" />
      </div>
    </div>
  );
};

export default ImageText;
