import React from 'react';
import './Main.css'; // Import the CSS file for styling
import Pic from '../main.png';
import Header from './Header';
import Card from './Card';

const Main = () => {
  const text1 = 'You Speak, We Listen';
  const text2 = 'We Value Integrity';
  return (
    <div className='container'>
        <Header />
        <div className="gradient-background">   
            <img
                src={Pic}
                alt="Your Image"
                className="centered-image"
            />
            <div className="gradient-overlay"></div>
        </div>
        <Card text1={text1} text2={text2}/>
    </div>
  );
};

export default Main;
