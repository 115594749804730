import React from 'react';
import './Text.css';
import Card from './Card';

const Text = () => {
  return (
    <div className='text-container'>
        <p className='text'>
            Join our community and take your Business to the next level
        </p>
    </div>

  )
}

export default Text