import React from 'react';
import './Header.css';
import { MdLanguage } from "react-icons/md";
import Logo from '../logo.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
      <nav className="nav-links">
        <ul>
          <li><a href="/">Features</a></li>
          <li><a href="/about">Examples</a></li>
          <li><a href="/services">Pricing</a></li>
          <li><a href="/contact">FAQ’s</a></li>
        </ul>
      </nav>
      <div className="language-selector">
      <MdLanguage color='#164983'/>{''}
        <select>
          <option value="en">ENGLISH</option>
          <option value="es">ARABIC</option>
        </select>
        
      </div>
      <div className="auth-buttons">
        <button className="login-btn">Login</button>
        <button className="signup-btn">Sign Up</button>
      </div>
    </header>
  );
};

export default Header;
