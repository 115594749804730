import React from 'react';
import './Footer.css'; // Import the CSS file for styling
import { FaInstagram, FaFacebookF } from 'react-icons/fa'; // Import Instagram and Facebook icons
import logo from '../logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        {/* Your logo goes here */}
        <img src={logo} alt="Logo" />
      </div>
      <div className="social-links">
        {/* Instagram icon with link */}
        <a href="https://www.instagram.com/your_instagram_account">
          <FaInstagram className="social-icon" /> Instagram
        </a>
        {/* Facebook icon with link */}
        <a href="https://www.facebook.com/your_facebook_account">
          <FaFacebookF className="social-icon" /> Facebook
        </a>
      </div>
      <div className="additional-links">
        {/* Five additional links */}
        <a href="/link1">Features</a>
        <a href="/link2">Examples</a>
        <a href="/link3">Pricing</a>
        <a href="/link4">FAQ’s</a>
        <a href="/link5" style={{marginLeft: 20 + 'em'}}>CONTACT US</a>
      </div>
    </footer>
  );
};

export default Footer;
