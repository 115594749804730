import React from 'react';
import './Card.css';
import { FaStar, FaHeart } from 'react-icons/fa'; // Import icons

const Card = ({ text1, text2 }) => {
  return (
    <div className='card'>
        <div className="icon-text-icon">
          <div className="icon-text">
            <FaStar className="icon" />
            <span className="icotext">{text1}</span>
          </div>
          
          <div className="icon-text">
            <FaHeart className="icon" />
            <span className="icotext">{text2}</span>
          </div>
        </div>
    </div>
  )
}

export default Card
