import React from 'react';
import './CardTwo.css'; // Import the CSS file for styling
import { FaPlus } from 'react-icons/fa'; // Import the plus icon

const CardTwo = () => {
  const itemList = [
    'Social Media Management',
    'Web Design and Hosting',
    'Video Production',
    'Google AdWords Ads',
    'mobile application design and development',
    'Business Identity Design',
    'Customer Relationship Management System'
  ];

  return (
    <div className="cardTwo">
      <div className="card-header">
        <h3>Our services</h3>
      </div>
      <div className="card-body">
        <ul>
          {itemList.map((item, index) => (
            <li key={index}>
              <span className="circle">
                <FaPlus className="plus-icon" />
              </span>
              {item}<hr />
            </li>
          ))}
        </ul>
        <p className='para'>Let’s start your day</p>
        <button>Get Started</button>
      </div>
    </div>
  );
};

export default CardTwo;
